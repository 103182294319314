import { BaseService }              from './base.service';
import { Injectable }               from '@angular/core';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { catchError, map, tap }     from 'rxjs/operators';
import { Observable ,  of }               from 'rxjs';
import { AppSettings }              from '../app.settings';
import { ToastrService }            from 'ngx-toastr';

import * as _                       from 'lodash';
import { Response }                 from '../models/response.model';
import { DamImage }                 from '../models/dam-image.model';

@Injectable()
export class ImageService extends BaseService {

    private cacheKey:string = 'images';

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService
    ) {
        super('/dam/gallery', http, appSettings, toastr);

        this.getImages('brand').subscribe(noop => {});
    }

    getImages(IDorKey:any = null): Observable<DamImage[]> {
        let key:string = this.cacheKey + '-' + IDorKey;

        if (this.cache.has(key)) { return of(this.cache.getItems(key)); }

        return this.http.get<Response>(this.apiURL + '/' + IDorKey + '/images')
            .pipe(
                map(response => {
                    let result = response.data;

                    _.each(result, (item, i) => { result[i] = new DamImage(item); });

                    this.cache.setItems(key, result);

                    return result;
                }),
                catchError(this.handleError('ImageService::getImages', []))
            );
    }
}
