import {Component, ViewEncapsulation, OnInit, HostListener, OnDestroy, AfterViewInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    Router,
    NavigationEnd,
    ActivatedRoute,
    NavigationStart,
    ActivationEnd
} from '@angular/router';
import { AppSettings } from './app.settings';
import { Settings } from './models/settings.model';
import { AuthService } from './services/auth.service';

import {filter, take} from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { ScrollHelper } from './shared/scrollHelper';
import { GoogleAnalytics } from './services/google-analytics.service';
import {PaymentService} from './services/payments.service';
import {ModalService} from './services/modal.service';
import {AlertType} from './enums/alert-type';
import {ProfileService} from './services/profile.service';
import {MobileCheckServiceService} from './services/mobile-check-service.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

    public settings: Settings;
    public showMenu = false;
    public menus = ['vertical', 'horizontal'];
    public menuOption: string;
    public menuTypes = ['default', 'compact', 'mini'];
    public menuTypeOption: string;
    public headerVisible = true;

    private alive = true;



    private scrollHelper: ScrollHelper;


    constructor(
        public appSettings: AppSettings,
        protected authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private viewportScroller: ViewportScroller,
        private googleAnalytics: GoogleAnalytics,
        private paymentService: PaymentService,
        private modalService: ModalService,
        private ngModal: NgbModal,
        private profileService: ProfileService,
        private mobileCheck: MobileCheckServiceService
    ) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() {
        this.scrollHelper = new ScrollHelper(this.viewportScroller);

        if (window.innerWidth <= 768) {
            this.settings.theme.showMenu = false;
            this.settings.theme.sideChatIsHoverable = false;
        }
        this.showMenu = this.settings.theme.showMenu;
        this.menuOption = this.settings.theme.menu;
        this.menuTypeOption = this.settings.theme.menuType;

        // this.router.events.subscribe((evt) => {
        //     if (!(evt instanceof NavigationEnd)) {
        //         return;
        //     }
        //     window.scrollTo(0, 0)
        // });
        /*Added to refresh the route when on same page. */
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        // this.router.events.subscribe((evt) => {
        //     if (evt instanceof NavigationEnd) {
        //         this.router.navigated = false;
        //         window.scrollTo(0, 0);
        //     }
        // });
        /* */
        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        ).subscribe((e: NavigationEnd) => {
            this.scrollHelper.handleScroll(e.urlAfterRedirects);

            // show warning dialog in case of failed transaction
            this.showFailedTransaction(e.url);
        });


        this.router.events.pipe(
                filter(event => {
                    return event instanceof ActivationEnd && !!event.snapshot.component
                    // return event instanceof ActivationEnd
                }),
            )
            .subscribe((event: ActivationEnd) => {
                const title = event['snapshot'].data['title'];
                if (title) {
                    this.googleAnalytics.navigationTo(this.router.routerState.snapshot.url, title);
                }
            });


        this.router.events.pipe(
            filter(e => e instanceof NavigationStart)
        ).subscribe((e: NavigationStart) => {
            // starting navigation event;
            // get current scroll offset
            this.scrollHelper.storeScroll(this.router.routerState.snapshot.url);
            if (e.url === '/not-supported' || (e.url === '/login' && !this.authService.user)) {
                // not supported page has it's own header or not need it
                this.headerVisible = false;
            } else {
                this.headerVisible = !!this.authService.user;
            }


            if (this.ngModal.hasOpenModals()) {
                this.ngModal.dismissAll();
            }

        });
    }


    ngAfterViewInit() {
        setTimeout(() => {
            this.showLatestNews();
            this.showTermsAndConditions();
            // this.profileService.showOrderDeadline();
        }, );
    }

    @HostListener('window:resize')
    public onWindowResize(): void {
        const showMenu = (window.innerWidth > 768);

        if (this.showMenu !== showMenu) {
            this.settings.theme.showMenu = showMenu;
        }
        this.showMenu = showMenu;
    }

    ngOnDestroy() {
        this.alive = false;
    }

    private getTitle() {
        let child = this.activatedRoute.firstChild;
        while (child) {
            if (child.firstChild) {
                child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data) {
                return child.snapshot.data;
            } else {
                return '';
            }
        }
        return '';
    }


    private showFailedTransaction(navigationUrl = '') {
        if (navigationUrl.startsWith('/account/transactions')) {
            return false;
        }

        if (this.paymentService.hasFailedTransactions) {
            const alertSettings = {
                type: AlertType.Confirm,
                class: 'modal-sm',
                headerClass: 'state modal-danger',
                headerIcon: 'fa-warning',
                title: 'Failed Transactions',
                message: 'You have failed transactions, you must update your credit card now to proceed.',
                actionLabel: 'Show'
            };
            this.modalService.showAlert(alertSettings).then( dialogResult => {
                if (dialogResult === true) {
                    this.router.navigate(['/account/transactions'], {queryParams: {showFailed: true}});
                }
            });

        }
    }


    private showTermsAndConditions() {
        this.profileService.showTermsAndConditions(true);
    }

    private showLatestNews() {
        this.profileService.showLatestNews();
    }


    get mobile(): boolean {
        return this.mobileCheck.mobile;
    }

}
