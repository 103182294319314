import {Action} from '@ngrx/store';

import {RepairRequest} from '../../interfaces/repair-request';
import {RepairFormConfig} from '../../interfaces/repair-form.config';
import {UploadImage} from '../../interfaces/upload-image';

export const ADDING_REPAIR_REQUEST_COMMENT = 'ADDING_REPAIR_REQUEST_COMMENT';
export const ADDED_REPAIR_REQUEST_COMMENT = 'ADDED_REPAIR_REQUEST_COMMENT';
export const SUBMITTING_REPAIR_REQUEST = 'SUBMITTING_REPAIR_REQUEST';
export const SUBMITTED_REPAIR_REQUEST = 'SUBMITTED_REPAIR_REQUEST';

export const FETCHING_REPAIR_REQUESTS = 'FETCHING_REPAIR_REQUESTS';
export const FETCHED_REPAIR_REQUESTS = 'FETCHED_REPAIR_REQUESTS';
export const FETCHING_REPAIR_REQUEST_DETAILS = 'FETCHING_REPAIR_REQUEST_DETAILS';
export const FETCHED_REPAIR_REQUEST_DETAILS = 'FETCHED_REPAIR_REQUEST_DETAILS';
export const FETCHED_REPAIR_CONFIG = 'FETCHED_REPAIR_CONFIG';
export const LOADING_MORE_REPAIR_REQUESTS = 'LOADING_MORE_REPAIR_REQUESTS';
export const LOADED_MORE_REPAIR_REQUESTS = 'LOADED_MORE_REPAIR_REQUESTS';
export const UPLOADING_REPAIR_REQUESTS_IMAGE = 'UPLOADING_REPAIR_REQUESTS_IMAGE';
export const UPLOADED_REPAIR_REQUESTS_IMAGE = 'UPLOADED_REPAIR_REQUESTS_IMAGE';
export const ADD_IMAGE_TO_CACHE = 'ADD_IMAGE_TO_CACHE';
export const FETCHING_ORIGINAL_IMAGE = 'FETCHING_ORIGINAL_IMAGE';
export const FETCHED_ORIGINAL_IMAGE = 'FETCHED_ORIGINAL_IMAGE';

export class AddingRepairRequestComment implements Action {
  readonly type = ADDING_REPAIR_REQUEST_COMMENT;
  constructor(public repairRequestId: number) {
  }
}

export class AddedRepairRequestComment implements Action {
  readonly type = ADDED_REPAIR_REQUEST_COMMENT;
  constructor(public repairRequest: RepairRequest) {
  }
}

export class FetchingRepairRequests implements Action {
  readonly type = FETCHING_REPAIR_REQUESTS;
}

export class FetchedRepairRequests implements Action {
  readonly type = FETCHED_REPAIR_REQUESTS;
  constructor(public repairRequests: RepairRequest[], public total: number) {}
}

export class FetchingRepairRequestDetails implements Action {
  readonly type = FETCHING_REPAIR_REQUEST_DETAILS;
  constructor(public repairRequestDetailsId: number) {}
}

export class FetchedRepairRequestDetails implements Action {
  readonly type = FETCHED_REPAIR_REQUEST_DETAILS;
  constructor(public repairRequestDetails: RepairRequest) {}
}

export class LoadingMoreRepairRequests implements Action {
  readonly type = LOADING_MORE_REPAIR_REQUESTS;
}

export class LoadedMoreRepairRequests implements Action {
  readonly type = LOADED_MORE_REPAIR_REQUESTS;
  constructor(public repairRequests: RepairRequest[]) {}
}

export class FetchedRepairFormConfig implements Action {
  readonly type = FETCHED_REPAIR_CONFIG;
  constructor(public repairFormConfig: RepairFormConfig) {
  }
}

export class UploadingRepairRequestImage implements Action {
  readonly type = UPLOADING_REPAIR_REQUESTS_IMAGE;
}

export class UploadedRepairRequestImage implements Action {
  readonly type = UPLOADED_REPAIR_REQUESTS_IMAGE;
  constructor(public image: UploadImage) {
  }
}

export class SubmittingRepairRequest implements Action {
  readonly type = SUBMITTING_REPAIR_REQUEST;
}

export class SubmittedRepairRequest implements Action {
  readonly type = SUBMITTED_REPAIR_REQUEST;
  constructor(public repairRequest: RepairRequest) {
  }
}

export class AddImageToCache implements Action {
  readonly type = ADD_IMAGE_TO_CACHE;
  constructor(public uploadImage: UploadImage) {
  }
}

export class FetchingOriginalImage implements Action {
  readonly type = FETCHING_ORIGINAL_IMAGE;
  constructor(public imageId: number) {
  }
}

export class FetchedOriginalImage implements Action {
  readonly type = FETCHED_ORIGINAL_IMAGE;
  constructor(public uploadImage: UploadImage) {
  }
}

export type RepairRequestsActions = FetchedRepairRequests | FetchingRepairRequests
    | FetchedRepairRequestDetails | FetchingRepairRequestDetails
    | LoadingMoreRepairRequests | LoadedMoreRepairRequests | FetchedRepairFormConfig
    | UploadedRepairRequestImage | UploadingRepairRequestImage
    | AddingRepairRequestComment | AddedRepairRequestComment
    | SubmittingRepairRequest | SubmittedRepairRequest | AddImageToCache
    | FetchingOriginalImage | FetchedOriginalImage;
