import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Address } from 'app/models/address.model';
import { AddressService } from 'app/services/address.service';
import { map, switchMap } from 'rxjs/operators';
import {
  loadDefaultAddresses,
  fetchDefaultAddresses,
  searchAddresses,
  loadSearchAddresses,
} from '../actions/address.actions';
import { of } from 'rxjs';

@Injectable()
export class AddressEffects {

  loadAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDefaultAddresses),
      switchMap((props: { userDefaultAddress: Address[] }) => {
        if (props.userDefaultAddress.length) {
          return of(props.userDefaultAddress);
        }

        return this.addressService.fetchUserAddressList();
      }),
      map((userDefaultAddress: Address[]) =>
        fetchDefaultAddresses({ userDefaultAddress })
      )
    )
  );

  searchAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSearchAddresses),
      switchMap(
        (props: {
          term: string;
          productId: number;
          isEmployee: boolean;
          accountAddresses: Address[];
        }) => {
          if (!props.term) {
            return of(props.accountAddresses);
          }

          if (props.isEmployee) {
            return this.addressService.searchAddresses(
              { match: props.term, product_id: props.productId },
            );
          } else {
            return of(
              props.accountAddresses.filter(
                (address) =>
                  address.searchKey &&
                  address.searchKey.indexOf(props.term) > -1
              )
            );
          }
        }
      ),
      map((address: Address[]) => searchAddresses({ address }))
    )
  );

  constructor(
    private actions$: Actions,
    private addressService: AddressService
  ) {}

}
