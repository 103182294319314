import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import cloneDeep from 'lodash/cloneDeep';

import { Address } from '../../../../models/address.model';
import { isEmptyArray } from '../../../../shared/utils';
import { AddressService } from '../../../../services/address.service';
import { flatternSortedAddressesByWslr } from '../../../../shared/helpers';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-preset-address-list',
  templateUrl: './preset-address-list.component.html',
  styleUrls: ['./preset-address-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PresetAddressListComponent {
  @Input() addresses: Address[] = [];

  @Input() disabled = false;

  @Output() onAddressListChanged = new EventEmitter<Address[]>();

  //@ViewChild('scrollContainer', { static: false }) scrollContainer;

  constructor(
    private addressService: AddressService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  get hasAddresses(): boolean {
    return !isEmptyArray(this.addresses);
  }

  onRemoveAddressHandler(index: number): void {
    const newAddresses = this.addresses.filter((_, i) => i !== index);
    this.addresses = newAddresses;
    this.onAddressListChanged.emit(newAddresses);
  }

  onAddAddressHandler(): void {
    const allAddresses = this.addressService.addresses;
    const computedAddressList = flatternSortedAddressesByWslr(
      [...allAddresses],
      this.authService.user.entity_id
    );

    if (isEmptyArray(allAddresses) || isEmptyArray(computedAddressList)) {
      // no addresses available
      this.toastr.warning(
        'No addresses available, Please add new address first'
      );
      return;
    }

    const selectedAddressesIds: number[] = this.addresses.map(
      (address: Address) => address.id
    );
    const availableAddresses = computedAddressList.filter(
      (address: Address) => !selectedAddressesIds.includes(address.id)
    );

    const newAddresses: Address[] = [
      ...this.addresses,
      isEmptyArray(availableAddresses)
        ? computedAddressList[0]
        : availableAddresses[0],
    ]

    this.onAddressListChanged.emit(newAddresses);
    // setTimeout(() => {
    //   this.scrollToBottom();
    // }, 100);
  }

  onAddressUpdatedHandler(address: Address, index: number): void {
    const newAddresses = cloneDeep(this.addresses);
    // find index by address id
    newAddresses[index] = address;
    this.addresses = newAddresses;
    this.onAddressListChanged.emit(newAddresses);
  }
}
