import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
    ADD_IMAGE_TO_CACHE,
    ADDED_REPAIR_REQUEST_COMMENT,
    ADDING_REPAIR_REQUEST_COMMENT,
    FETCHED_ORIGINAL_IMAGE,
    FETCHED_REPAIR_CONFIG,
    FETCHED_REPAIR_REQUEST_DETAILS,
    FETCHED_REPAIR_REQUESTS,
    FETCHING_ORIGINAL_IMAGE,
    FETCHING_REPAIR_REQUEST_DETAILS,
    FETCHING_REPAIR_REQUESTS,
    LOADED_MORE_REPAIR_REQUESTS,
    LOADING_MORE_REPAIR_REQUESTS,
    RepairRequestsActions,
    SUBMITTED_REPAIR_REQUEST,
    SUBMITTING_REPAIR_REQUEST,
    UPLOADED_REPAIR_REQUESTS_IMAGE,
    UPLOADING_REPAIR_REQUESTS_IMAGE
} from '../actions/repairt-requests.actions';
import {isEmptyArray, updateItemInArray} from '../../shared/utils';
import {RepairRequest} from '../../interfaces/repair-request';
import {RepairFormConfig} from '../../interfaces/repair-form.config';
import {UploadImage} from '../../interfaces/upload-image';


export interface IRepairRequestsState {
    listOfRequestsReceived: boolean;
    fetchingRepairRequest: boolean;
    submittingRepairRequest: boolean;
    fetchingRepairRequestDetailsId: number;
    addingRepairRequestComment: number;
    loadingMore: boolean;
    repairRequests: RepairRequest[];
    totalRequests: number;
    config: RepairFormConfig;
    uploadingImage: boolean;
    uploadedImages: UploadImage[]; // thumbnails
    fetchingOriginalImage: number;
    imageCache: Object;
    originalImages: Object; // original images
}


export const initialState: IRepairRequestsState = {
    listOfRequestsReceived: false,
    fetchingRepairRequest: false,
    submittingRepairRequest: false,
    fetchingRepairRequestDetailsId: null,
    addingRepairRequestComment: null,
    loadingMore: false,
    repairRequests: [],
    totalRequests: 0,
    config: null,
    uploadedImages: [],
    uploadingImage: false,
    imageCache: {},
    originalImages: {},
    fetchingOriginalImage: null
};


export function repairRequestsReducer(state = initialState, action: RepairRequestsActions) {
    switch (action.type) {
        case FETCHED_REPAIR_REQUESTS:
            return {...state, fetchingRepairRequest: false,  listOfRequestsReceived: true,
                repairRequests : [...action.repairRequests], totalRequests: action.total};
        case FETCHING_REPAIR_REQUESTS:
            return {...state, fetchingRepairRequest: true};
        case FETCHING_REPAIR_REQUEST_DETAILS:
            return {...state, fetchingRepairRequestDetailsId: action.repairRequestDetailsId};
        case FETCHED_REPAIR_REQUEST_DETAILS:
            if (action.repairRequestDetails) {
                const newRepairRequests = updateItemInArray<RepairRequest>(state.repairRequests, action.repairRequestDetails, true, 'zendesk_id');
                return {...state, fetchingRepairRequestDetailsId: null, repairRequests : newRepairRequests};
            }
            return {...state, fetchingRepairRequestDetailsId: null};
        case LOADING_MORE_REPAIR_REQUESTS:
            return {...state, loadingMore: true};
        case LOADED_MORE_REPAIR_REQUESTS:
            return  {...state, loadingMore: false, repairRequests : [...state.repairRequests, ...action.repairRequests]};
        case FETCHED_REPAIR_CONFIG:
            return {...state, config: action.repairFormConfig};
        case UPLOADING_REPAIR_REQUESTS_IMAGE:
            return {...state, uploadingImage: true};
        case UPLOADED_REPAIR_REQUESTS_IMAGE:
            if (action.image) {
                const images = [...state.uploadedImages, action.image];
                return {...state, uploadingImage: false, uploadedImages: images};
            } else {
                return {...state, uploadingImage: false};
            }
        case ADDING_REPAIR_REQUEST_COMMENT:
            return {...state, addingRepairRequestComment: action.repairRequestId};
        case ADDED_REPAIR_REQUEST_COMMENT:
            if (action.repairRequest) {
                const newRepairRequests = updateItemInArray<RepairRequest>(state.repairRequests, action.repairRequest, false, 'zendesk_id');
                return {...state, addingRepairRequestComment: null, uploadedImages: [],  repairRequests : newRepairRequests};
            } else {
                return {...state, addingRepairRequestComment: null};
            }
        case SUBMITTING_REPAIR_REQUEST:
            return {...state, submittingRepairRequest: true};
        case SUBMITTED_REPAIR_REQUEST:
            if (action.repairRequest) {
                const newRepairRequests = [action.repairRequest, ...state.repairRequests];
                return {...state, submittingRepairRequest: null, repairRequests : newRepairRequests, uploadedImages: []};
            } else {
                return {...state, submittingRepairRequest: false};
            }
        case ADD_IMAGE_TO_CACHE:
            if (action.uploadImage) {
                const imageCache = {...state.imageCache};
                imageCache[action.uploadImage.id] = action.uploadImage;
                return {...state, imageCache};
            }
            return state;
        case FETCHING_ORIGINAL_IMAGE:
            return {...state, fetchingOriginalImage: action.imageId};
        case FETCHED_ORIGINAL_IMAGE:
            if (action.uploadImage) {
                const originalImages = {...state.originalImages};
                originalImages[action.uploadImage.id] = action.uploadImage;
                return {...state, fetchingOriginalImage: null, originalImages};
            }
            return {...state, fetchingOriginalImage: null}
        default:
            return state;
    }
}

export const getRepairRequestsState = createFeatureSelector<IRepairRequestsState>('repairRequests');

export const isFetchingRepairRequests =
    createSelector(getRepairRequestsState, (state: IRepairRequestsState) => state.fetchingRepairRequest === true);

export const repairRequests = createSelector(getRepairRequestsState, (state: IRepairRequestsState) => state.repairRequests);

export const repairRequestsResult = createSelector(getRepairRequestsState, (state: IRepairRequestsState) =>
    ({repairRequests: state.repairRequests, total: state.totalRequests, listReceived: state.listOfRequestsReceived}));

export const hasRepairRequests =
    createSelector(getRepairRequestsState, (state: IRepairRequestsState) => {
        return state.fetchingRepairRequest === false && !isEmptyArray(state.repairRequests);
    });

export const hasMoreRequests =
    createSelector(getRepairRequestsState, (state: IRepairRequestsState) => state.totalRequests > state.repairRequests.length);

export const isLoadingMore = createSelector(getRepairRequestsState, (state: IRepairRequestsState) => state.loadingMore);


export const repairFormConfig = createSelector(getRepairRequestsState, (state: IRepairRequestsState) => state.config);

export const isUploadingImage = createSelector(getRepairRequestsState, (state: IRepairRequestsState) => state.uploadingImage);

export const uploadedImages = createSelector(getRepairRequestsState, (state: IRepairRequestsState) => state.uploadedImages);

export const repairRequestDetailsById = createSelector(getRepairRequestsState, (state: IRepairRequestsState, props) => {
    if (!props.id) {
        return null;
    }
    return state.repairRequests.find(r => r.zendesk_id === props.id);
})
// as an improvement for deprication we can try this way, but it needs to be tested
// https://stackoverflow.com/questions/69647168/what-is-the-alternative-to-using-selectors-with-props-in-ngrx
// export const repairRequestDetailsById = (id: number) =>
//   createSelector(getRepairRequestsState, (state: IRepairRequestsState) => {
//     if (!id) {
//       return null;
//     }
//     return state.repairRequests.find((r) => r.zendesk_id === id);
//   });

export const fetchingRequestDetailsId  =
    createSelector(getRepairRequestsState, (state: IRepairRequestsState, props) => {
        return props.id && state.fetchingRepairRequestDetailsId === props.id;
    }
)

export const isAddingRepairRequestComment =
    createSelector(getRepairRequestsState, (state: IRepairRequestsState, props) => state.addingRepairRequestComment === props.id);


export const isSubmittingRepairRequest =
    createSelector(getRepairRequestsState, (state: IRepairRequestsState) => state.submittingRepairRequest);


export const getImageFromCacheById =
    createSelector(getRepairRequestsState, (state: IRepairRequestsState, props) => state.imageCache[props.id]);

export const isListOfRequestsFetched =
    createSelector(getRepairRequestsState, (state: IRepairRequestsState) => state.listOfRequestsReceived);


export const originalImageById =
    createSelector(getRepairRequestsState, (state: IRepairRequestsState, props) => state.originalImages[props.id]);

export const fetchingOriginalImageById =
    createSelector(getRepairRequestsState, (state: IRepairRequestsState, props) => state.fetchingOriginalImage === props.id);
