import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged, map, switchMap,
  takeWhile,
} from 'rxjs/operators';
import {of, Subject} from 'rxjs';

import { Address } from '../../../../models/address.model';
import {sortAddresses} from '../../../../shared/helpers';
import { AuthService } from '../../../../services/auth.service';
import { ModalService } from '../../../../services/modal.service';
import { MobileCheckServiceService } from '../../../../services/mobile-check-service.service';
import { AddressType } from 'app/enums/address-type';
import {AddressService} from '../../../../services/address.service';

const DROPDOWN_WIDTH = 300;

@Component({
  selector: 'app-preset-address-item',
  templateUrl: './preset-address-item.component.html',
  styleUrls: ['./preset-address-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PresetAddressItemComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() address: Address;
  @Input() showAddButton = false;
  @Input() disabled = false;

  private alive = true;

  addr_id: string;
  addressesSubject: Subject<string> = new Subject<string>();
  addressesLoading = false;
  computedAddressList: Address[] = [];
  // selectedAddress: Address;
  itemsTemplateHeader = 'Your Addresses';
  addressType = AddressType;

  private initialized;

  @ViewChild('select', { static: false }) addressSelector;

  @Input() className = '';

  // output event when address has been selected
  @Output() addressUpdated = new EventEmitter<Address>();
  @Output() addressRemoved = new EventEmitter<void>();
  @Output() anotherAddressAdded = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    private addressService: AddressService,
    private modalService: ModalService,
    private mobileCheck: MobileCheckServiceService,
  ) {
  }

  ngOnInit(): void {
    this.addressesSubject
      .pipe(
        takeWhile(() => this.alive),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term) => {
          if (!term) {
            return of(this.addressService.validatedAddressed);
          }
          this.addressesLoading = true;
          return this.addressService.searchAddresses({
            match: term,
          }).pipe(
            map( retResult => {
              if (!retResult) {
                return [];
              }
              return [...retResult.corporateAddresses, ...retResult.userAddresses];
            })
          )

        })
      )
      .subscribe((result) => {
        this.addressesLoading = false;
        this.computedAddressList = sortAddresses(result);
      });

    // only valid addresses can be should in dropbox
    this.computedAddressList = [...this.addressService.validatedAddressed];
    this.addr_id = this.address?.uniqueAddressId;
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.initialized = true));
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onSelect(data: Address | Event): void {
    if (data instanceof Address) {
      this.addr_id = data?.uniqueAddressId;
      this.addressUpdated.emit(data);
    }
  }

  onCloseDropdown(): void {
    // reset search items to default state
    this.computedAddressList = [...this.addressService.validatedAddressed];
  }

  onAddAddress() {
    this.modalService.editAddress(null, true).then((result) => {
      if (result) {
        this.computedAddressList =  [...this.addressService.validatedAddressed];
        this.addr_id = result.uniqueAddressId;
        this.addressUpdated.emit(result);
      }
    });
    this.addressSelector.close();
    return false;
  }

  onRemoveAddress(): void {
    this.addressRemoved.emit();
  }

  onAddAnotherAddress(): void {
    this.anotherAddressAdded.emit();
  }

  get canAddAddresses(): boolean {
    return this.authService.canAddAddress;
  }

  get isEmployee(): boolean {
    return this.authService.isEmployee;
  }

  get mobile(): boolean {
    return this.mobileCheck.mobile;
  }

  get dropDownClasses(): string {
    if (!this.mobile || !this.initialized) {
      return `${this.className}`;
    }

    let leftAligned = false;
    if (this.addressSelector) {
      const rect = this.addressSelector.element.getBoundingClientRect();
      leftAligned = rect.right >= DROPDOWN_WIDTH;
    }

    return `${this.className} ${this.mobile ? 'mobile ' : 0} ${
      leftAligned ? 'left-aligned' : ''
    }`;
  }

  get selectedAddress(): Address {
    return this.address;
  }


}
