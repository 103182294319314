import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {catchError, finalize, map, switchMap, tap} from 'rxjs/operators';
import {action, computed, observable} from 'mobx';
import {ToastrService} from 'ngx-toastr';
import {forkJoin, Observable, of} from 'rxjs';

import {BaseService} from './base.service';
import {AppSettings} from '../app.settings';
import {Response} from '../models/response.model';
import {Address} from '../models/address.model';
import {isEmptyArray, moveElementInArray, updateItemInArray} from '../shared/utils';
import {AuthService} from './auth.service';
import {WslrAddress} from '../interfaces/wslr-address';
import {getSortedAddressesByWslr, getSortedWslrIds, sortAddresses} from '../shared/helpers';
import {API_URL} from '../constants/api-urls';
import {Order} from '../models/order.model';
import {PresetAddress} from '../interfaces/preset-address';
import {AddressType} from 'app/enums/address-type';
import {OrderItemDelivery} from '../models/order-item-delivery.model';


const MAX_SEARCH_LIMIT = 500;

export interface AddressResult {
    is_valid: boolean,
    exists: boolean,
    addresses: Address[],
    savedAddress: Address
}


export interface AddressInput  {
    match?: string,
    label?: string; // company name
    city?: string; //  city/town
    state?: string; //  # state/county
    country?: string; //  country
    zip_code?: string; //  postal code
    address_type?: number;
    product_id?: number;
}


export interface SearchAddressResult {
    userAddresses: Address[],
    corporateAddresses: Address[]
    corporateAddressesTotalCount: number;
    userAddressesTotalCount: number;
}


@Injectable()
export class AddressService extends BaseService {

    @observable userAddresses: Address[] = [];

    @observable sortedAddresses: WslrAddress[] = [];

    @observable updatingAddressId = null;

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        private authService: AuthService
    ) {
        super('/account/addresses', http, appSettings, toastr);
    }

    /**
     * requested once -  right after user is logged in
     * promise is require to ensure addresses are loaded
     */
    fetchUserAddresses(): Promise<void> {
        return new Promise<void>( (resolve) => {
            if (!this.authService.canSeeAddresses) {
                this.setAddresses([]);
                resolve();
                return;
            }

            this.fetchUserAddressList().subscribe(addresses => {
                this.setAddresses(addresses);
                resolve();
            });
        });
    }

    fetchUserAddressList(): Observable<Address[]> {
        return forkJoin([
          this.fetchCorporateAddresses(),
          this.searchAddresses({ 'address_type': AddressType.Personal }).pipe(
            map(result => {
                return result?.userAddresses || [];
            })
          )
        ]).pipe(map(([arr1, arr2]) => [...arr1, ...arr2]));
      }

      private fetchCorporateAddresses(): Observable<Address[]> {
        return this.http.get<Response>(`${API_URL}/entities/locations`).pipe(
          map((response) => {
            let result = [];
            if (response.data) {
              result = response.data.map(
                (item, i) =>
                  new Address({
                    ...item,
                    isWSLR: this.authService.isEmployee,
                  })
              );
            }

            return result;
          }),
          catchError(this.handleError('AddressService::addresses', []))
        );
      }

    public searchAddresses(input: AddressInput, includeDisabled = false): Observable<SearchAddressResult> {
        const url = `${API_URL}/search-address`;
        const  headersBody = {'Range': `${MAX_SEARCH_LIMIT}-0`};

        return this.http.post<HttpResponse<Response>>(url, input, {observe: 'response', headers: headersBody} ).pipe(
            map( httpResponse => {
                const response = new Response(httpResponse.body);

                const addressedCount: number = +httpResponse.headers.get('x-count-addresses');
                const locationsCount: number = +httpResponse.headers.get('x-count-locations');
                let disabledAddressesCount = 0;
                let disabledLocationsCount = 0;
                let addresses = [];
                let entities = [];

                if (!isEmptyArray(response.data)) {
                    const  allAddresses = response.data.map( item => new Address(item));
                    // split  addresses by type and handle disabled ones
                    addresses = allAddresses.filter( a => a.address_type === 1);
                    entities = allAddresses.filter( a => a.address_type !== 1);

                    if (!includeDisabled) {
                        const addressesToRemove = addresses
                            .map((address, index) => !address.active ? index : -1)
                            .filter(index => index !== -1);

                        disabledAddressesCount = addressesToRemove.length;

                        addressesToRemove
                            .reverse()
                            .forEach(index => addresses.splice(index, 1));

                        const entitesToRemove = entities
                            .map((address, index) => !address.active ? index : -1)
                            .filter(index => index !== -1);

                        disabledLocationsCount = entitesToRemove.length;

                        entitesToRemove
                            .reverse()
                            .forEach(index => entities.splice(index, 1));
                    }

                }
                return {corporateAddressesTotalCount: locationsCount - disabledLocationsCount,
                    userAddressesTotalCount: addressedCount - disabledAddressesCount,
                    userAddresses: addresses, corporateAddresses: entities};
            }),
            catchError(this.handleError('AddressService::searchAddresses', null))
        )
    }

    @action public setDefault(address: Address, wslrIds: number[] = []): Observable<boolean> {

        const requestBody = {
            ...address
        };

        this.updatingAddressId = address.id;
        const url = `${API_URL}/account/addresses/${address.id}`;
        return this.http.put<Response>(url, requestBody,
            {headers: this.xsrfTokenHeader})
        .pipe(
            switchMap( () => this.fetchUserAddressList()),
            tap((addresses) => {
                if (!isEmptyArray(addresses)) {
                    this.setAddresses(addresses);
                    this.toastr.success('Default address has been changed');
                }
            }),
            catchError(this.handleError('AddressService::setDefault', null)),
            finalize(() => this.updatingAddressId = null)
        );
    }

    save(address: Address): Observable<AddressResult> {
        // always validate address before save
        return this.validate(address).pipe(
            switchMap(result => {
                if (!result) {
                    // validation failed
                    return of(null);
                }

                if (!result.is_valid) {
                    // address is not valid
                    return of(result);
                }

                // check if returned address is the same
                if (result.addresses.length === 1 && address.isTheSame(result.addresses[0]) !== true) {
                    // returned address is not the same -- show to user
                    return of(result);
                }

                address.is_valid = true;
                address.address_type = 1; // user address
                address.type = 'OTHER_ADDR';

                // store validated result
                return this.store(address).pipe(
                     map(savedAddress => {
                         // we should keep address_type
                        savedAddress.address_type = address.address_type;
                        savedAddress.type = 'OTHER_ADDR';
                        result.savedAddress = savedAddress;
                        return result;
                     })
                );
            })
        );
    }

    public store(address: Address): Observable<Address> {
        let request = null;
        if (!address) {
            return of (null);
        }

        const url = `${API_URL}/account/addresses`;

        // save address
        if (!address.id) {
            request = this.http.post<Response>(url, address, {headers: this.xsrfTokenHeader});
        } else {
            request = this.http.put<Response>(`${url}/${address.id}`, address, {headers: this.xsrfTokenHeader});
        }

        return request.pipe(
            map( (response: Response) => {
                if (response && response.data) {
                    return new Address(response.data);
                } else {
                    return null;
                }
            }),
            catchError(this.handleError('AddressService::save', null)),
            tap( (result: Address) => {
                if (result) {
                    this.setAddresses(updateItemInArray<Address>(this.userAddresses, result));
                    this.toastr.success('Your address has been saved');
                }
            })
        );

    }

    private validate(address: Address): Observable<AddressResult> {
        if (!address) {
            return of(null);
        }

        if (address.is_valid) {
            // it's already valid - no need to validate again
            return of({
                is_valid: true,
                exists: false,
                addresses: [],
                savedAddress: null
            });
        }

        const validatedAddress = {
            country_code: address.country_code,
            state: address.state,
            zip_code: address.zip_code,
            city: address.city,
            street_1: address.street_1,
            street_2: address.street_2 || ''
        }

        // send address id for already exist address
        if (address.id) {
            validatedAddress['id'] = address.id;
        }

        const url = `${API_URL}/account/addresses/validate`;

        return this.http.post<Response>(url, validatedAddress).pipe(
            map(response => {
                if (response && response.data) {
                    let exists = false;
                    let valid = false;
                    let addresses: Address[] = [];

                    if (response.data.existing_address) {
                        exists = true;
                        addresses = [new Address({...response.data.existing_address, wslr_ids: address.wslr_ids})];
                    } else {
                        valid = response.data.is_valid;
                        if (!response.data.is_valid && !response.data.addresses.length) {
                            const errorMsg  = response.data.error_message || 'No valid addresses found. Please update and try again.';
                            this.toastr.error(errorMsg);
                        }
                        addresses = Array.isArray(response.data.addresses) ?
                            response.data.addresses.map(a => new Address({...a,
                                label: address.label,
                                is_valid: true,
                                wslr_ids: address.wslr_ids}) ) : [];
                    }

                    for (let i = 0; i < addresses.length; i++) {
                        addresses[i].client_address_id = address.client_address_id;
                    }

                    return {
                        is_valid: valid,
                        exists,
                        addresses,
                        saved: false
                    }
                }
                return null;
            }),
            catchError(this.handleError('AddressService::save', null))
        );
    }

    async delete(address: Address) {
        const response = await this.http.delete<Response>(this.apiURL + '/' + address.id)
            .pipe(
                catchError(this.handleError('AddressService::delete', null))
            ).toPromise();
        if (response) {
            const addresses  = updateItemInArray<Address>(this.userAddresses, address.id);
            this.setAddresses(addresses);
            this.toastr.success('Your address has been deleted');
            return true;
        }
    }

    search(term: string, productID?: number): Observable<Address[]> {
        if (!term || term.replace(/ /g, '' ) === '' || term.length < 2) {
            return of([]);
        }
        if (this.cache.has(term + '-' + productID)) {
            return of(this.cache.getItems(term + '-' + productID));
        }

        const params = { search: term };
        if (productID) {
            params['product_id'] = productID;
        }

        const url = `${API_URL}/entities/locations`;
        return this.http.post<Response>(url, params, {headers: this.xsrfTokenHeader})
            .pipe(
                map(response => {
                    let result = [];
                    if (response.data) {
                        result = response.data.map( item => new Address({...item, isWSLR: this.authService.isEmployee}));
                    }

                    this.cache.setItems(term + '-' + productID, result);

                    return result;
                }),
                catchError(this.handleError('AddressService::search', []))
            );
    }

    private sortAddressesByWLSR(addresses: Address[]): WslrAddress[] {
        return getSortedAddressesByWslr(addresses, this.authService.user.entity_id);
    }


    @action public setAddresses(addresses: Address[]) {
        this.userAddresses = sortAddresses(addresses);
        // this.sortedAddresses = this.userAddresses;
    }

    // sorted user address - default is always first
    @computed public get addresses(): Address[] {
        // return flatternSortedAddressesByWslr([...this.validatedAddressed], this.authService.user.entity_id);
        return this.validatedAddressed;
    }

    @computed public get validatedAddressed(): Address[] {
        // return this.userAddresses.filter(a => {
        //     // all corporate addresses are valid
        //     if (a.isCorporate) {
        //         return true;
        //     }
        //     return a.is_valid;
        // });

        const validatedAddressed = this.userAddresses.filter(a => {
            // all corporate addresses are valid
            if (a.isCorporate) {
                return true;
            }
            if (a.isDisabled) {
                return false;
            }
            return a.is_valid;
        });

        // need to sort again
        return sortAddresses(validatedAddressed);
    }

    @computed public get wlsrIds(): number[] {
        return getSortedWslrIds(this.userAddresses, this.authService.user.entity_id);
    }

    @action public moveAddress(from: number, to: number, wslrId: number) {

        const wslrAddress = this.sortedAddresses.find( a => a.wslrId === wslrId);
        if (wslrAddress) {

            const fromAddress =  wslrAddress.addresses[from];
            if (!this.authService.canMoveAddress(fromAddress)) {
                return;
            }

            wslrAddress.addresses = moveElementInArray<Address>(wslrAddress.addresses, from, to);
            const sorted = [];
            let ord = 0;
            if (!isEmptyArray(wslrAddress.default)) {
                wslrAddress.default.forEach( (address) => {
                    sorted.push({id: address.id, ord});
                    ord++;
                });
            }
            wslrAddress.addresses.forEach( (address) => {
                sorted.push({id: address.id, ord});
                ord++;
            });

            this.http.post<Response>(`${this.apiURL}/sort`, sorted).subscribe( () => {});

        }
        // this.setAddresses(moveElementInArray<Address>(this.userAddresses, from, to));
    }

    // public getPresetAddresses(order: Order): Address[] {
    //     if (isEmptyArray(this.addresses)) {
    //         return [];
    //     }
    //     if (isEmptyArray(order?.attr_preset_addresses)) {
    //         return [];
    //     }
    //
    //     return this.addresses.filter( a => order.preset_addresses.includes(a.id));
    // }

    private fetchAddressesByIds(ids: PresetAddress[]): Observable<Address[]> {
        if (isEmptyArray(ids)) {
            return of([]);
        }
        const url = `${API_URL}/account/addresses/for-ids`;
        return this.http.post<Response>(url, ids).pipe(
            map( response => {
                if (!isEmptyArray(response.data)) {
                    return response.data.map( item => new Address({...item, isWSLR: this.authService.isEmployee}));
                }
                return [];
            }),
            catchError(this.handleError('AddressService::fetchAddressesByIds', []))
        )
    }

    public getPresetAddressesForOrder(order: Order): Observable<Address[]> {
        if (!order) {
            return of([]);
        }

        if (isEmptyArray(order.attr_preset_addresses)) {
            return of([]);
        }

        // user addresses don't have entity id
        const userAddressesIds = this.addresses.map( a => a.id);
        const missingAddresses  =  order.attr_preset_addresses.filter( i => {
            if (!!i.entity_id) {
                return true;
            }
            return !userAddressesIds.includes(i.id);
        });

        return this.fetchAddressesByIds(missingAddresses).pipe(
          map( retAddresses => {
            const result: Address[] = [];

            order.attr_preset_addresses.forEach( a => {
                if (!a.entity_id) {
                    // user address use case
                    let address = this.addresses.find( i => i.id === a.id);
                    if (!address) {
                        // look up in returned addresses
                        address = retAddresses.find( i => (i.id === a.id && i.entity_id === undefined));
                    }

                    if (!!address) {
                        result.push(address);
                    }
                } else {
                    // location use case
                    const address = retAddresses.find( i => (i.id === a.id && i.entity_id === a.entity_id));
                    if (!!address) {
                        result.push(address);
                    }
                }
            })

            return result;
          })
        );
    }

    public getMainContactFromPersonalAddress(delivery: OrderItemDelivery): string {
        if (!delivery || delivery.address_type === AddressType.Corporate) {
            return '';
        }
        const address  = this.validatedAddressed.find(a => a.id === delivery.addr_id);
        if (!address) {
            return '';
        }

        const  contact = address.contact_details.find( c => c.main);
        return contact?.contact || '';

    }

}
