import { createAction, props } from '@ngrx/store';
import { Address } from 'app/models/address.model';

export const loadDefaultAddresses = createAction(
  '[Default Address Trigger] Load the Addresses',
  props<{ userDefaultAddress: Address[] }>()
);

export const fetchDefaultAddresses = createAction(
  '[Address Service API Call] Fetch Default Addresses',
  props<{ userDefaultAddress: Address[] }>()
);

export const loadSearchAddresses = createAction(
  '[Address Trigger] Search Addresses',
  props<{
    term: string;
    productId: number;
    isEmployee: boolean;
    accountAddresses: Address[];
  }>()
);

export const searchAddresses = createAction(
  '[Address Service API Call] Search Addresses',
  props<{ address: Address[] }>()
);
