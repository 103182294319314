import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';

import {ToastrService} from 'ngx-toastr';
import * as fromRecommendedProductsReducer from '../store/reducers/recommended-products.reducer' ;
import {ApiRecommendedProductsService} from './api-recommended-products.service';
import {FetchedProductOrdersInfo, FetchingProductOrdersInfo} from '../store/actions/recommended-products.actions';
import {ProductOrdersInfo} from '../interfaces/product-orders-info';


@Injectable()
export class RecommendedProductsService {
    constructor(
        private store: Store<fromRecommendedProductsReducer.IRecommendedProductsState>,
        private api: ApiRecommendedProductsService,
        private toastr: ToastrService
    ) {
    }

    public getProductOrdersIfo(productId: number) {
        if (!productId) {
            return;
        }
        this.store.dispatch(new FetchingProductOrdersInfo());
        this.api.getProductOrderInfo(productId).subscribe( result => {

            const totalQuantity =  result
                .reduce( (total, i) => (total + i.shipment_qty), 0) || 0;

            const data: ProductOrdersInfo = {
                productId,
                totalQuantity,
                info: result
            }
            this.store.dispatch(new FetchedProductOrdersInfo(data));
        });
    }

    public exportRecommendedProductToExcel(productId: number): Observable<boolean> {
        return this.api.exportRecommendedProductToExcel(productId);
    }

    public exportRecommendedProductToPDF(productId: number): Observable<boolean> {
        return this.api.exportRecommendedProductToPDF(productId);
    }
}
