import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { Order } from '../../../models/order.model';
import { OrderService } from '../../../services/order.service';
import { AuthService } from '../../../services/auth.service';
import { OrderItem } from '../../../models/order-item.model';
import { OrderItemDelivery } from '../../../models/order-item-delivery.model';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {validateWbsForUnique} from '../../../shared/helpers';
import {MultiTenantService} from '../../../services/multi-tenant.service';

@Component({
    selector: 'app-internal-order-modal',
    templateUrl: './internal-order.modal.component.html',
    styleUrls: ['./internal-order.modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InternalOrderModalComponent implements OnInit, OnDestroy {

    _internalOrderValue = '';
    private alive = true;
    inProcess = false;

    order: Order;
    orderItem: OrderItem;
    orderItemDelivery: OrderItemDelivery;
    waitForUpdate = true;

    constructor(private orderService: OrderService,
                private authService: AuthService,
                private activeModal: NgbActiveModal,
                private toastr: ToastrService,
                private multiTenantService: MultiTenantService) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    saveInternalOrder() {
        this._internalOrderValue = !!this._internalOrderValue ? this._internalOrderValue.trim() : '';
        if (this._internalOrderValue !== this.orderItemDelivery.internalOrder) {
            if (this.waitForUpdate) {
                const updatedOrder = new Order(this.order);
                const updatedOrderItem = updatedOrder.createOrderItem(this.orderItem);
                const updateOrderItemDelivery = new OrderItemDelivery(this.orderItemDelivery);
                if (this.order.isWBSDefined) {
                    updateOrderItemDelivery.wbs = this._internalOrderValue;
                } else {
                    updateOrderItemDelivery.internal_order = this._internalOrderValue;
                }
                updatedOrderItem.updateDelivery(updateOrderItemDelivery, this.order.isOnDemand);

                if (this.multiTenantService.canShowWBS) {
                    const wbsValidationMsg  = validateWbsForUnique(updatedOrder, updatedOrderItem);
                    if (!!wbsValidationMsg) {
                        this.toastr.warning(wbsValidationMsg, 'Warning');
                        return;
                    }
                }


                this.inProcess = true;
                this.orderService.updateOrderItem(updatedOrder, updatedOrderItem)
                  .subscribe(data => {
                      this.inProcess = false;
                      if (data) {
                          this.close({internalOrder: this._internalOrderValue});
                      }
                  });
            } else {
                this.close({internalOrder: this._internalOrderValue});
            }

        } else {
            this.close({internalOrder: this._internalOrderValue});
        }




    }

    close(next: any = null) {
        this.activeModal.close(next);
    }


    get internalOrderLabel(): string {
        if (this.order.isNotReal) {
            // fake order
            return 'Order Code';
        }
        return this.order.isWBSDefined ? 'WBS' : 'Internal Order';
    }

    onValueChanged(event: string) {
        this._internalOrderValue = event;
    }

    onCleared() {
        this._internalOrderValue = '';
    }
}
