import { createReducer, on } from '@ngrx/store';

import {
  fetchDefaultAddresses,
  searchAddresses,
} from '../actions/address.actions';
import { Address } from 'app/models/address.model';

export interface AddressState {
  userDefaultAddress: Address[];
  address: Address[];
}

export const initialState: AddressState = {
  userDefaultAddress: [],
  address: [],
};

export const addressReducer = createReducer(
  initialState,
  on(fetchDefaultAddresses, (state, { userDefaultAddress }) => {
    return { ...state, userDefaultAddress };
  }),
  on(searchAddresses, (state, { address }) => {
    return { ...state, address };
  })
);
