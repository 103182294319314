import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap, takeWhile, tap} from 'rxjs/operators';

import {Address} from '../../../models/address.model';
import {AddressService} from '../../../services/address.service';
import {AuthService} from '../../../services/auth.service';
import {OrderItemDelivery} from '../../../models/order-item-delivery.model';
import {Entity} from '../../../models/entity.model';
import {ModalService} from '../../../services/modal.service';
import {computeShipmentLabel, sortAddresses} from '../../../shared/helpers';
import {OrderItem} from '../../../models/order-item.model';
import {Order} from '../../../models/order.model';
import {Product} from '../../../models/product.model';
import {MobileCheckServiceService} from '../../../services/mobile-check-service.service';
import {AddressType} from 'app/enums/address-type';

const DROPDOWN_WIDTH = 300;

@Component({
  selector: 'app-ship-to-address',
  templateUrl: './ship-to-address.component.html',
  styleUrls: ['./ship-to-address.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShipToAddressComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private alive = true;

  addr_id: string;
  addressesSubject: Subject<string> = new Subject<string>();
  addressesLoading = false;
  computedAddressList: Address[] = [];
  itemsTemplateHeader = '';
  addressType = AddressType;

  private initialized;
  private _orderItem: OrderItem;

  @ViewChild('select', { static: false }) addressSelector;

  @Input() disabled = false;

  @Input() delivery: OrderItemDelivery;

  @Input() isInline = false;

  @Input() className = '';

  _entity: Entity;
  entityLabel: string;

  // output event when address has been selected
  @Output() addressSelected = new EventEmitter<Address>();

  @Input() order: Order;

  @Input() set orderItem(val: OrderItem) {
    this._orderItem = val;
  }

  constructor(
    private addressService: AddressService,
    private authService: AuthService,
    private modalService: ModalService,
    private mobileCheck: MobileCheckServiceService,
  ) { }

  ngOnInit(): void {
    this.addressesSubject
      .pipe(
        takeWhile(() => this.alive),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.addressesLoading = true),
        switchMap(term => {
          if (!term) {
            return of(this.addressService.validatedAddressed);
          }
          return this.addressService.searchAddresses({
            match: term,
            product_id: this.productId,
          }).pipe(
            map( retResult => {
              if (!retResult) {
                return [];
              }
              return [...retResult.corporateAddresses, ...retResult.userAddresses];
            })
          )
      }))
      .subscribe( result => {
        this.addressesLoading = false;
        this.computedAddressList = sortAddresses(result);
      });

    // only valid addresses can be should in dropbox
    this.computedAddressList = [...this.addressService.validatedAddressed];

    if (this.delivery) {
      this.addr_id = this.delivery.uniqueAddressId;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.initialized = true));
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onSelect(data): void {
    if (data instanceof Event) {
      return;
    }
    this.addressSelected.emit(data);
  }

  onCloseDropdown(): void {
    // reset search items to default state
    this.resetAddressList(this.addressService.validatedAddressed);
  }

  resetAddressList(addresses: Address[] = []) {

    this.computedAddressList = [...addresses];

  }


  addAddress() {
    this.modalService.editAddress(null, true).then((result) => {
      if (result) {
        this.resetAddressList(this.addressService.validatedAddressed);
        this.addr_id = result.uniqueAddressId;
        this.addressSelected.emit(result);
      }
    });
    this.addressSelector.close();
    return false;
  }

  get addressLabel() {
    if (!this.delivery) {
      return '';
    }

    const contact  = this.addressService.getMainContactFromPersonalAddress(this.delivery);
    return computeShipmentLabel(this.delivery, contact);
  }

  get product(): Product {
    return this._orderItem?.product;
  }

  get productId(): number {
    return this.product?.id;
  }

  get isEmployee(): boolean {
    return this.authService.isEmployee;
  }

  get isAutoship(): boolean {
    return this.order.isCustom;
  }

  get isDecisionPoint(): boolean {
    return this.order.isDecisionPoint;
  }

  get attention(): string {
    return this.order?.attr_attention || '';
  }

  get wslrEntity(): Entity {
    return this.order.isWSLR ? this.order?.entity : null;
  }

  get deliveries(): OrderItemDelivery[] {
    return this._orderItem?.deliveries || [];
  }

  get canAddAddresses(): boolean {
    return this.authService.canAddAddress;
  }

  get mobile(): boolean {
    return this.mobileCheck.mobile;
  }

  get dropDownClasses(): string {
    if (!this.mobile || !this.initialized) {
      return `${this.className}`;
    }

    let leftAligned = false;
    if (this.addressSelector) {
      const rect = this.addressSelector.element.getBoundingClientRect();
      leftAligned = rect.right >= DROPDOWN_WIDTH;
    }

    return `${this.className} ${this.mobile ? 'mobile ' : 0} ${
      leftAligned ? 'left-aligned' : ''
    }`;
  }
}
