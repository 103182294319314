import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {RecommendedProductsService} from '../../../../services/recommended-products.service';
import {Store} from '@ngrx/store';
import * as fromRecommendedProductsReducer from '../../../../store/reducers/recommended-products.reducer';
import {Observable} from 'rxjs';
import {ProductOrdersInfo} from '../../../../interfaces/product-orders-info';
import {MobileCheckServiceService} from '../../../../services/mobile-check-service.service';
import {OrderItemService} from '../../../../services/order-item.service';
import {Order} from '../../../../models/order.model';
import {OrderService} from '../../../../services/order.service';
import {takeWhile} from 'rxjs/operators';

@Component({
  selector: 'app-product-orders-details',
  templateUrl: './product-orders-details.component.html',
  styleUrls: ['./product-orders-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductOrdersDetailsComponent implements OnInit, OnDestroy {

  private _activeOrder: Order;
  private alive = true;

  @Input() productId: number;

  @Input() modal = false;

  @Output() showFull = new EventEmitter<void>();

  constructor(private productInfoService: RecommendedProductsService,
              private mobileCheck: MobileCheckServiceService,
              private orderItemService: OrderItemService,
              private orderService: OrderService,
              private store: Store<fromRecommendedProductsReducer.IRecommendedProductsState>) { }

  ngOnInit(): void {
    if (this.productId) {


      this.orderService.activeOrderChanged.pipe(
          takeWhile( () => this.alive)
      ).subscribe( order => {
        if (order && order.id > 0) {
          if (order.id !== this._activeOrder?.id) {
            this.productInfoService.getProductOrdersIfo(this.productId);
            this._activeOrder = order;
          }
        }

      });

    }
  }
  ngOnDestroy() {
    this.alive = false;
  }


  get isFetching$(): Observable<boolean> {
    return  this.store.select(fromRecommendedProductsReducer.isFetchingProductOrdersInfo);
  }

  get ordersDetails$(): Observable<ProductOrdersInfo> {
    return this.store.select(fromRecommendedProductsReducer.productOrdersInfoById, {id: this.productId});
  }

  get mobile(): boolean {
    return this.mobileCheck.mobileDevice;
  }

  exportToExcel() {
    this.productInfoService.exportRecommendedProductToExcel(this.productId).subscribe( result => {
      if (result) {
        this.orderItemService.showRecommendedProductExportResult('xlsx');
      }
    });

  }

  exportToPDF() {
    this.productInfoService.exportRecommendedProductToPDF(this.productId).subscribe( result => {
      if (result) {
        this.orderItemService.showRecommendedProductExportResult('pdf');
      }
    });

  }

  showFullView() {
    this.showFull.emit();
  }
}
