<div class="modal-md ship-to">
    <div class="modal-header state">
        <h1 class="full-width text-align-center">{{internalOrderLabel}}</h1>
        <button type="button" (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">

        <div class="input-group mt-3">
            <app-clearable-input [defaultValue]="order.internalOrder"
                                 [value]="orderItemDelivery.internalOrder"
                                (valueChange)="onValueChanged($event)"
                                 (clear)="onCleared()"
            ></app-clearable-input>
        </div>

        <div class="mt-4 d-flex justify-content-between">
            <button class="btn btn-outline-primary control" type="reset" (click)="close()">Cancel</button>
            <button [disabled]="inProcess"
                    class="btn btn-primary control" type="button" (click)="saveInternalOrder()">
                <i *ngIf="inProcess" class="fal fa-sync rotate"></i>&nbsp;Save</button>
        </div>
    </div>

</div>

