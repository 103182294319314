import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
    ADDED_PAYMENT_METHOD,
    ADDING_PAYMENT_METHOD,
    FETCHED_PAYMENT_METHODS,
    FETCHING_PAYMENT_METHODS,
    MADE_DEFAULT_PAYMENT_METHOD,
    MAKING_DEFAULT_PAYMENT_METHOD,
    PaymentsActions,
    REMOVED_PAYMENT_METHOD,
    REMOVING_PAYMENT_METHOD,
    UPDATED_PAYMENT_METHOD,
    UPDATING_PAYMENT_METHOD,
} from '../actions/payment-methods.actions';
import {PaymentMethod} from '../../models/payment-method.model';
import {CostCenter} from '../../models/cost-center.model';
import {updateItemInArray} from '../../shared/utils';
import {CreditCard} from '../../models/credit-card.model';


export interface IPaymentsMethodsState {
    fetchingPaymentMethods: boolean;
    addingPaymentMethod: boolean;
    updatingPaymentMethodId: number;
    removingPaymentMethodId: number;
    makingDefaultPaymentMethodId: number;
    paymentMethods: PaymentMethod[];
}


export const initialState: IPaymentsMethodsState = {
    fetchingPaymentMethods: false,
    addingPaymentMethod: false,
    updatingPaymentMethodId: null,
    removingPaymentMethodId: null,
    makingDefaultPaymentMethodId: null,
    paymentMethods: []
};


export function paymentMethodsReducer(state = initialState, action: PaymentsActions) {
    switch (action.type) {
        case FETCHING_PAYMENT_METHODS:
            return {...state, fetchingPaymentMethods: true};
        case FETCHED_PAYMENT_METHODS:
            return {...state, fetchingPaymentMethods: false, paymentMethods: [...action.payments]};
        case MAKING_DEFAULT_PAYMENT_METHOD:
            return {...state, makingDefaultPaymentMethodId: action.paymentMethodId};
        case MADE_DEFAULT_PAYMENT_METHOD:
            if (!action.paymentMethod) {
                return {...state, makingDefaultPaymentMethodId: null};
            }
            return {...state, makingDefaultPaymentMethodId: null,
                paymentMethods: updateItemInArray<PaymentMethod>(state.paymentMethods, action.paymentMethod)};
        case UPDATING_PAYMENT_METHOD:
            return {...state, updatingPaymentMethodId: action.paymentMethodId};
        case UPDATED_PAYMENT_METHOD:
            if (!action.paymentMethod) {
                return {...state, updatingPaymentMethodId: null};
            }
            return {...state, makingDefaultPaymentMethodId: null,
                paymentMethods: updateItemInArray<PaymentMethod>(state.paymentMethods, action.paymentMethod)};
        case ADDING_PAYMENT_METHOD:
            return {...state, addingPaymentMethod: true};
        case ADDED_PAYMENT_METHOD:
            if (!action.paymentMethod) {
                return {...state, addingPaymentMethod: false};
            }
            return {...state, addingPaymentMethod: null,
                paymentMethods: updateItemInArray<PaymentMethod>(state.paymentMethods, action.paymentMethod, true)};
        case REMOVING_PAYMENT_METHOD:
            return {...state, removingPaymentMethodId: action.paymentMethodId};
        case REMOVED_PAYMENT_METHOD:
            if (!action.paymentMethodId) {
                return {...state, removingPaymentMethodId: action.paymentMethodId};
            }
            const updatedPaymentMethods = state.paymentMethods.filter(p => p.id !== action.paymentMethodId);
            return {...state, removingPaymentMethodId: null,
                paymentMethods: updatedPaymentMethods};


        default:
            return state;
    }
}

export const getPaymentMethodsState = createFeatureSelector<IPaymentsMethodsState>('paymentMethods');

export const isFetchingPaymentMethods =
    createSelector(getPaymentMethodsState, (state: IPaymentsMethodsState) => state.fetchingPaymentMethods === true);

export const paymentMethods =
    createSelector(getPaymentMethodsState, (state: IPaymentsMethodsState, props) => {
        if (props.includeCreditCards) {
            return state.paymentMethods;
        }
        return state.paymentMethods.filter( p => p.isAccount()) as CostCenter[];
    });


export const creditCards = createSelector(getPaymentMethodsState, (state: IPaymentsMethodsState) => {

    const cards  = state.paymentMethods.filter(  p => (p.isCard()  && (p as CreditCard).isValid)) as CreditCard[];

    const sortedCards = cards.sort( (a, b) => {
        if (a.active && !b.active) {
            return -1;
        }
        if (!a.active && b.active) {
            return 1;
        }

        return 0;
    });
    return sortedCards;

});

export const sapAccounts =
    createSelector(getPaymentMethodsState, (state: IPaymentsMethodsState, props) => {
        return state.paymentMethods.filter( p => p.isAccount()) as CostCenter[];
    });

