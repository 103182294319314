import {Action} from '@ngrx/store';
import {PaymentMethod} from '../../models/payment-method.model';

export const FETCHING_PAYMENT_METHODS = 'FETCHING_PAYMENT_METHODS';
export const FETCHED_PAYMENT_METHODS = 'FETCHED_PAYMENT_METHODS';

export const MAKING_DEFAULT_PAYMENT_METHOD = 'MAKING_DEFAULT_PAYMENT_METHOD';
export const MADE_DEFAULT_PAYMENT_METHOD = 'MADE_DEFAULT_PAYMENT_METHOD';

export const UPDATING_PAYMENT_METHOD = 'UPDATING_PAYMENT_METHOD';
export const UPDATED_PAYMENT_METHOD = 'UPDATED_PAYMENT_METHOD';

export const REMOVING_PAYMENT_METHOD = 'REMOVING_PAYMENT_METHOD';
export const REMOVED_PAYMENT_METHOD = 'REMOVED_PAYMENT_METHOD';

export const ADDING_PAYMENT_METHOD = 'ADDING_PAYMENT_METHOD';
export const ADDED_PAYMENT_METHOD = 'ADDED_PAYMENT_METHOD';


export class FetchingPayments implements Action {
  readonly type = FETCHING_PAYMENT_METHODS;
}

export class FetchedPayments implements Action {
  readonly type = FETCHED_PAYMENT_METHODS;
  constructor(public payments: PaymentMethod[]) {
  }
}


export class MakingDefaultPaymentMethod implements Action {
  readonly type = MAKING_DEFAULT_PAYMENT_METHOD;
  constructor(public paymentMethodId: number) {
  }
}

export class MadeDefaultPaymentMethod implements Action {
  readonly type = MADE_DEFAULT_PAYMENT_METHOD;
  constructor(public paymentMethod: PaymentMethod) {
  }
}


export class UpdatingPaymentMethod implements Action {
  readonly type = UPDATING_PAYMENT_METHOD;
  constructor(public paymentMethodId: number) {
  }
}

export class UpdatedPaymentMethod implements Action {
  readonly type = UPDATED_PAYMENT_METHOD;
  constructor(public paymentMethod: PaymentMethod) {
  }
}

export class AddingPaymentMethod implements Action {
  readonly type = ADDING_PAYMENT_METHOD;
}

export class AddedPaymentMethod implements Action {
  readonly type = ADDED_PAYMENT_METHOD;
  constructor(public paymentMethod: PaymentMethod) {
  }
}


export class RemovingPaymentMethod implements Action {
  readonly type = REMOVING_PAYMENT_METHOD;
  constructor(public paymentMethodId: number) {
  }
}

export class RemovedPaymentMethod implements Action {
  readonly type = REMOVED_PAYMENT_METHOD;
  constructor(public paymentMethodId: number) {
  }
}



export type PaymentsActions = FetchingPayments | FetchedPayments | MakingDefaultPaymentMethod | MadeDefaultPaymentMethod
    | UpdatingPaymentMethod | UpdatedPaymentMethod | AddingPaymentMethod | AddedPaymentMethod
    | RemovingPaymentMethod | RemovedPaymentMethod;
